import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Timeline from '../../../../components/ui/timeline-ru'
import ImageOne from '../../../../data/images/bg/materials/solder/wire-500x350.jpg'
import ImageTwo from '../../../../data/images/bg/materials/solder/bars-500x350.jpg'
import ImageThree from '../../../../data/images/bg/materials/solder/preform-500x350.jpg'
import { SectionWrap } from './style'


const TimelineArea = (props) => {
    const content = [
        {
            year: "ТРУБЧАТЫЙ",
            image: ImageOne,
            title: "Припой для пайки ручной",
            desc: "Припой для пайки ручной применяется в виде проволоки намотанной на шпулю. Поставляются припои свинцовые и припои бессвинцовые. Применяются для ручной пайки на паяльных станциях и автоматической пайки монтажными роботами. В последнее время широко применяется трубчатый припой – припой для пайки с флюсом внутри, которые являются основным материалом для ручного монтажа печатных плат и ремонта.",
            path: "/материалы-для-smt/паяльная-паста-и-припой/припой-для-пайки/припой-трубчатый",
            lang: "Подробно..."
        },
        {
            year: "БРУСКИ",
            image: ImageTwo,
            title: "Припой для пайки машинной",
            desc: "Припой для пайки машинной применяется в виде брусков различной геометрии. Используется в основном для загрузки в машины пайки волной припоя, машины селективной пайки и оборудование для пайки погружением. Припой для пайки машинной отличается высокой чистотой, что бы обеспечить минимальное содержание примесей в открытых ваннах. Поставляется припой для свинцовой и бессвинцовой технологии. Использование качественных припоев для пайки групповой снижает количество образования шлама в процессе пайки и увеличивает срок жизни припоя в ванне.",
            path: "/материалы-для-smt/паяльная-паста-и-припой/припой-для-пайки/припой-для-волновой-пайки",
            lang: "Подробно..."
        },
        {
            year: "ПРЕФОРМЫ",
            image: ImageThree,
            title: "Припой в фольге и преформах",
            desc: "В последнее время довольно часто применяют процессы монтажа, где тяжело использовать стандартный припой в проволоке. Для обеспечения максимальных требований заказчика припой может поставляться в виде фольги (в ленте и в листах). Для процессов требующих точного дозирования припоя, как технология монтажа в отверстия печатных плат на пасту ( технология Pin in Paste), применяются преформы. Дозы припоя точной геометрической формы с определенными допусками с возможностью установки на оборудовании для монтажа SMD.",
            path: "/материалы-для-smt/паяльная-паста-и-припой/припой-для-пайки/припой-в-виде-фольги-преформ",
            lang: "Подробно..."
        }
    ]
    const contentua = [
        {
            year: "ТРУБЧАТИЙ",
            image: ImageOne,
            title: "Припій для паяння ручного",
            desc: "Припій для паяння ручного застосовується у вигляді дроту намотаного на шпулю. Поставляються припої свинцеві та припої безсвинцеві. Застосовуються для ручного паяння на паяльних станціях та автоматичного паяння монтажними роботами. Останнім часом широко застосовується трубчастий припій – припій для пайки з флюсом усередині, які є основним матеріалом для ручного монтажу друкованих плат та ремонту.",
            path: "/ua/матеріали-для-smt/матеріали-для-паяння/припій-для-паяння/припій-трубчастий",
            lang: "Докладно..."
        },
        {
            year: "БРУСКИ",
            image: ImageTwo,
            title: "Припій для паяння машинного",
            desc: "Припій для паяння машинного застосовується у вигляді брусків різної геометрії. Використовується в основному для завантаження в машини паяння хвилею припою, машини селективного паяння та обладнання для паяння зануренням. Припій для пайки машинної відрізняється високою чистотою, щоб забезпечити мінімальний вміст домішок у відкритих ваннах. Постачається припій для свинцевої та безсвинцевої технології. Використання якісних припоїв для пайки групової знижує кількість утворення шламу в процесі паяння та збільшує термін життя припою у ванні.",
            path: "/ua/матеріали-для-smt/матеріали-для-паяння/припій-для-паяння/припій-для-хвильової-пайки",
            lang: "Докладно..."
        },
        {
            year: "ПРЕФОРМИ",
            image: ImageThree,
            title: "Припій у фользі та преформах",
            desc: "Останнім часом досить часто застосовують процеси монтажу, де важко використовувати стандартний припій у дроті. Для забезпечення максимальних вимог замовника припій може постачатися у вигляді фольги (у стрічці та листах). Для процесів, що потребують точного дозування припою, як технологія монтажу в отвори друкованих плат на пасту (технологія Pin in Paste), застосовуються преформи. Дози припою точної геометричної форми з певними допусками з можливістю встановлення на обладнанні для монтажу SMD.",
            path: "/ua/матеріали-для-smt/матеріали-для-паяння/припій-для-паяння/припій-у-фользі-преформах",
            lang: "Докладно..."
        }
    ]
    if (props.lang === "ru") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Припой для пайки разделяется на два основных типа: припой для пайки ручной и припой для пайки машинной. Припой для пайки ручной применятся для монтажа печатных плат на паяльной станции и для ремонта. Поставляется в проволоке намотанной на шпулю. Припой для пайки машинной применяется для загрузки машин пайки волной припоя и машин селективной пайки.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={content} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    } else if (props.lang === "uk") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Припій для паяння поділяється на два основних типи: припій для паяння ручного та припій для паяння машинного. Припій для паяння ручний застосовується для монтажу друкованих плат на паяльній станції та для ремонту. Поставляється у дроті намотаною на шпулю. Припій для пайки машинної застосовується для завантаження машин пайки хвилею припою та машин селективного паяння.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={contentua} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    }
}

export default TimelineArea
